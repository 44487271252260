import $ from 'jquery';
import ScrollMagic from 'scrollmagic'
import {TimelineMax, TweenMax} from 'gsap'
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$(function () {

    const $section          = $('section.services-container');
    const $serviceContainer = $section.find('.service-content-container');
    const $items            = $section.find('.service-title-container > .service-title-item');
    const $headerContainer  = $section.find('.service-content-title');
    const $textContainer    = $section.find('.service-content-text');
    const $texts            = $textContainer.children();
    const $images           = $section.find('.image-container .image');
    const $container        = $section.find('.container').last();

    $items
        .on('mouseenter', function () {

            const $item = $(this);
            $item.removeClass('animated-indicator');
            $items.removeClass('active');
            $item.addClass('active');

            //$textContainer.text($item.data('text'));

            $texts.removeClass('active');
            $images.removeClass('active');

            $($texts.get($item.index())).addClass('active');
            $($images.get($item.index())).addClass('active');

            $serviceContainer.css({
                background:     `url(${$item.data('thumb')}) no-repeat left center`,
                backgroundSize: 'contain',
            })

            $headerContainer.text($item.text());

        });

    /*    const $heading         = $section.find('.service-heading');
        const controller       = new ScrollMagic.Controller();
        const timeline         = new TimelineMax();

        timeline
            .add(TweenMax.fromTo($headingParts.get(0), {scale: 0, opacity: 0}, {scale: 1, opacity: 1}, 500))
            .add(TweenMax.fromTo($headingParts.get(1), {scale: 0, opacity: 0}, {scale: 1, opacity: 1}, 500), 0)
            .add(TweenMax.fromTo($headingParts.get(2), {scale: 0, opacity: 0}, {scale: 1, opacity: 1}, 500), 0)
            .add(TweenMax.fromTo($headingDividers.get(0), {margin: '25% 0', opacity: 0}, {
                margin:  '1rem 0',
                opacity: 1
            }, 500), 0)
            .add(TweenMax.fromTo($headingDividers.get(1), {margin: '25% 0', opacity: 0}, {
                margin:  '1rem 0',
                opacity: 1
            }, 500), 0);

        new ScrollMagic.Scene({
            triggerElement: $heading.get(0),
            triggerHook:    1,
            offset:         120
        })
            .setTween(timeline)
            //.addIndicators({name: "2 (duration: 4000)"})
            .addTo(controller);*/

    const $serviceTitleContainer = $section.find('.service-title-item');
    const controller             = new ScrollMagic.Controller();
    const $row                   = $container.find('.row');
    const $cols                  = $row.children();

    $cols.each(function () {

        const timeline = new TimelineMax();
        const $e       = $(this);
        const $img     = $e.find('.img-wrapper');
        const $title   = $e.find('h4');
        const $p       = $e.find('p');
        const $more    = $e.find('h5');

        timeline
            .add(TweenMax.fromTo($img.get(0), {opacity: 0, scale: 2}, {scale: 1, opacity: 1, ease: 'shine'}, 500))
            .add(TweenMax.fromTo($title.get(0), {translateY: '2rem', opacity: 0}, {translateY: 0, opacity: 1, ease: 'shine'}, 500), 0)
            .add(TweenMax.fromTo($p.get(0), {translateY: '2rem', opacity: 0}, {translateY: 0, opacity: 1, ease: 'shine'}, 500), 0)
            .add(TweenMax.fromTo($more.get(0), {translateY: '2rem', opacity: 0}, {translateY: 0, opacity: 1, ease: 'shine'}, 500), 0)
        ;

        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook:    0.7,
            offset:         -150,
        })
            .setTween(timeline)
            //.addIndicators({name: "FOS"})
            .addTo(controller);

    });

    /*$(window)
        .on('resize', function () {

            controller.enabled(document.body.clientWidth >= 1024);
        })
        .trigger('resize');*/

});
