import './client-list'; // Uses Slick for Client logos
import './services-at-glance'; // Services at glance
import $ from 'jquery';
import ScrollMagic from 'scrollmagic'
import {Linear, TimelineMax, TweenMax} from 'gsap'
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

/**  Not used anymore **/
// import './deprecated/behind-the-scenes';
// import './deprecated/meet-the-team';
// import './deprecated/our-philosophy';
// import './deprecated/numbers';
// import './deprecated/case-studies';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$(function () {

    const $body     = $('body');
    const $contents = $('#contents');
    const $loader   = $('#primaryLoader');
    const parallaxClassName = '.parallax-container';


    $contents.removeClass('d-none');
    $loader.hide();
    $body.removeClass('disabled');

    /*$loader.fadeOut(250, function () {
        $body.removeClass('disabled');
    });*/

    const controller = new ScrollMagic.Controller();
    $body.find('section, footer').each(function () {

        const $section     = $(this);
        const $container   = $section.find('.lined-title-container');
        const $headingText = $section.find('.line-title');
        const $headingLine = $section.find('.horizontal-line');

        if (!$container.length) {
            return;
        }

        const timeline = new TimelineMax();

        timeline
            .add(TweenMax.fromTo($headingText, 0.5, {
                overwrite: false,
                transform: 'translateX(20%)',
                opacity:   0
            }, {
                transform: 'translateX(0)',
                opacity:   1,
            }, 0.2), 0)
            .add(TweenMax.fromTo($headingLine, 0.5, {
                width: 0,
            }, {
                width: '100%',
            }, 0));

        new ScrollMagic.Scene({
            triggerElement: $container.get(0),
            triggerHook:    1,
            offset:         64
        })
            .setTween(timeline)
            .addTo(controller);

    });

    $('#navbarSupportedContent')
        .find('.nav-item')
        .each(function () {

            if (!$(this).data('section')) {
                return;
            }

            const $section = $($(this).data('section'));

            new ScrollMagic.Scene({
                triggerElement: $section.get(0),
                duration:       $section.outerHeight(),
                triggerHook:    1,
                offset:         120
            })
                .setTween(TweenMax.fromTo(
                    $(this).get(0),
                    $section.outerHeight(),
                    {background: 'linear-gradient(90deg, #E5E5E5 0%, transparent 0%)'},
                    {background: 'linear-gradient(90deg, #E5E5E5 100%, transparent 100%)'}
                ))
                //.addIndicators({name: $(this).data('section')})
                .addTo(controller);

        });

    const controller2 = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}});

    new ScrollMagic.Scene({
        triggerElement: $(parallaxClassName).get(0)
    })
        .setTween(
            $(parallaxClassName).find('.parallax').get(0),
            {y: '80%', ease: Linear.easeNone}
        )
        .addTo(controller2);
});
