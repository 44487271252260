import $ from 'jquery';

$(function () {
    const $section = $('section.clients-container');

    if ($section.length > 0) {
        // Dynamically import slick-carousel

        const originalAddEventListener = EventTarget.prototype.addEventListener;
        EventTarget.prototype.addEventListener = function (type, listener, options) {
            const isTouch = ['touchstart', 'touchmove'].includes(type);
            const isObject = typeof options === 'object';

            if (isTouch && (!isObject || options.passive === undefined)) {
                options = isObject ? { ...options, passive: true } : { passive: true };
            }

            return originalAddEventListener.call(this, type, listener, options);
        };


        import('slick-carousel').then(() => {
            const $container = $section.find('.clients-bg');

            $container.slick({
                autoplay: true,
                autoplaySpeed: 0,
                arrows: false,
                speed: 5000,
                cssEase: 'linear',
                slidesToShow: 6,
                slidesToScroll: 1,
                swipeToSlide: true,
                pauseOnHover: false,
                infinite: true,
                draggable: false,
                touchMove: false,
                responsive: [
                    { breakpoint: 2000, settings: { slidesToShow: 5 } },
                    { breakpoint: 1800, settings: { slidesToShow: 4 } },
                    { breakpoint: 1200, settings: { slidesToShow: 3 } },
                    { breakpoint: 650,  settings: { slidesToShow: 2 } },
                    { breakpoint: 450,  settings: { slidesToShow: 1 } }
                ]
            });

            const $logos = $container.find('.client-image img');

            $logos.each(function () {
                $(this).hover(
                    function () {
                        $(this).css({
                            transform: 'scale(1.1)',
                            transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
                        });
                    },
                    function () {
                        $(this).css({
                            transform: 'scale(1)',
                            transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
                        });
                    }
                );
            });

            const observerOptions = {
                root: null,
                threshold: 0.1
            };

            const logoObserver = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.style.opacity = '1';
                        entry.target.style.transform = 'translateY(0)';
                    }
                });
            }, observerOptions);

            $logos.each(function () {
                $(this).css({
                    opacity: '0',
                    transform: 'translateY(20px)',
                    transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)'
                });
                logoObserver.observe(this);
            });
        });
    }
});
